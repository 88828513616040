import * as React from 'react';
import { Route } from 'react-router';

import loadable from '@loadable/component';
import { PageLoaderFallback } from '../layout/PageLoaderFallback';

const Index = loadable(
  () => import(/* webpackPrefetch: true */ './about/Index'),
  {
    fallback: <PageLoaderFallback />,
  }
);

const AboutUsConcept = loadable(
  () => import(/* webpackPrefetch: true */ './about/Consept'),
  {
    fallback: <PageLoaderFallback />,
  }
);

const AboutUs: React.FC = () => {
  return (
    <>
      <Route exact path="/om-oss" component={Index} />
      <Route exact path="/om-oss/konsept" component={AboutUsConcept} />
    </>
  );
};

export default AboutUs;
